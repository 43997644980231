import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Importing Main Component
import App from "./App.vue";

// Importing Axios
//import AxiosPlugin from "vue-axios-cors";
import axios from "axios";
import VueAxios from "vue-axios";
import dateFormat from "dateformat";

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Using Axios
//Vue.use(AxiosPlugin, axios);
Vue.use(VueAxios, axios);
Vue.prototype.$dateFormat = dateFormat;

axios.defaults.baseURL = "https://api.clinicabonnadona.com/api/v1/";
//axios.defaults.baseURL = "http://localhost:3000/api/v1/";
//axios.defaults.baseURL = "http://172.16.52.216:3000/api/v1/";
//axios.defaults.baseURL = "https://190.131.222.108:8088/api/v1/";
//axios.defaults.baseURL = "https://guarded-peak-65061.herokuapp.com/api/v1/";
//axios.defaults.withCredentials = false;
//axios.defaults.headers.common["X-Authorization"] = process.env.VUE_APP_API_KEY;
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
//console.log(process.env.VUE_API_KEY);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
