<template>
  <div>
    <b-container fluid>
      <b-row class="mt-5">
        <b-col xl="8" offset-xl="2">
          <b-row>
            <!-- -->
            <b-col sm="12" md="12" lg="12" xl="5">
              <b-form-group id="fieldset-1">
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="form.date"
                  class="mb-2 rounded-input"
                  placeholder="Seleccione una Fecha"
                  today-button
                  today-variant="success"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <!-- -->
            <b-col sm="12" md="12" lg="12" xl="5">
              <b-form-group id="fieldset-2">
                <b-form-select
                  v-model="form.pavilion"
                  :options="options"
                  class="rounded-input"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- -->
            <b-col sm="12" md="12" lg="12" xl="2">
              <b-form-group id="fieldset-2">
                <b-form-select
                  v-model="form.schedule"
                  :options="options_am_pm"
                  class="rounded-input"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- -->
          <b-row>
            <b-col xl="6" sm="12" offset-xl="3" lg="12" md="12" xs="12">
              <b-row>
                <b-col>
                  <b-button
                    variant="success"
                    class="mb-2"
                    block
                    pill
                    @click="getTurnDoctorDelivery"
                  >
                    <b-icon-search></b-icon-search> Buscar Entregas</b-button
                  >
                </b-col>
                <b-col xl="6" sm="12" lg="12" md="12" xs="12">
                  <b-button
                    variant="warning"
                    block
                    pill
                    @click="clearTurnDoctorDelivery"
                  >
                    <b-icon-brush></b-icon-brush> Limpiar</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <b-overlay :show="show" rounded="sm">
            <turnDoctorDelivery :data="items"></turnDoctorDelivery>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import turnDoctorDelivery from "@/components/turn-doctor-delivery/turnDoctorDelivery.vue";
export default {
  name: "turnDoctor",
  components: {
    turnDoctorDelivery,
  },
  data() {
    return {
      form: {
        date: "",
        pavilion: null,
        schedule: null,
      },
      show: false,
      options: [
        { value: null, text: "Seleccione un Pabellón" },
        { value: "HOSP GEN ABRAHAM", text: "HOSP GEN ABRAHAM" },
        { value: "HOSP ONC JUAN PABLO", text: "HOSP ONC JUAN PABLO" },
        { value: "HOSP GEN GABRIEL", text: "HOSP GEN GABRIEL" },
        {
          value: "OBSERVACION 2 URG ADULTO-",
          text: "OBSERVACION 2 URG ADULTO-",
        },
        {
          value: "UCI INTERMEDIA ADULTO4 AURORA",
          text: "UCI INTERMEDIA ADULTO4 AURORA",
        },
        {
          value: "OBSERVACION URGENCIA CENTRAL-",
          text: "OBSERVACION URGENCIA CENTRAL-",
        },
        {
          value: "OBSERVACION URG PEDIATRICA-",
          text: "OBSERVACION URG PEDIATRICA-",
        },
        {
          value: "UCI 3",
          text: "UCI 3",
        },
        {
          value: "TAMO",
          text: "TAMO",
        },
        {
          value: "HOSP ONC TULIPÁN",
          text: "HOSP ONC TULIPÁN",
        },
        {
          value: "HOSP ONC AZUCENA",
          text: "HOSP ONC AZUCENA",
        },
        {
          value: "HOSP ONC HORTENSIA",
          text: "HOSP ONC HORTENSIA",
        },
        {
          value: "HOSP ONC LÍRIO",
          text: "HOSP ONC LÍRIO",
        },
        {
          value: "HOSP ONC PETUNIA",
          text: "HOSP ONC PETUNIA",
        },
        {
          value: "ALTA COMPLEJIDAD",
          text: "ALTA COMPLEJIDAD",
        },
        {
          value: "HOSP ONC IRIS",
          text: "HOSP ONC IRIS",
        },
        {
          value: "UCI. ADULTOS 2",
          text: "UCI. ADULTOS 2",
        },
        {
          value: "HOSP ONC JACOB",
          text: "HOSP ONC JACOB",
        },
        {
          value: "HOSP ONC ISAAC",
          text: "HOSP ONC ISAAC",
        },
        {
          value: "HOSP GEN DAVID",
          text: "HOSP GEN DAVID",
        },
        {
          value: "HOSP ONC EMANUEL",
          text: "HOSP ONC EMANUEL",
        },
        {
          value: "HOSP ONC SARA",
          text: "HOSP ONC SARA",
        },
        {
          value: "HOSP ONC JUANA MARIA",
          text: "HOSP ONC JUANA MARIA",
        },
        {
          value: "OBSERVACION HOSPITAL DIA",
          text: "OBSERVACION HOSPITAL DIA",
        },
        {
          value: "UCI PEDIATRICA",
          text: "UCI PEDIATRICA",
        },
        {
          value: "HOSP GEN MARIA",
          text: "HOSP GEN MARIA",
        },
        {
          value: "UCI 1",
          text: "UCI 1",
        },
        {
          value: "UCI HEMATO ONCOLOGICA",
          text: "UCI HEMATO ONCOLOGICA",
        },
      ],
      options_am_pm: [
        { value: null, text: "Jornada" },
        { value: "AM", text: "NOCTURNO" },
        { value: "PM", text: "DIURNO" },
      ],
      items: [],
    };
  },
  methods: {
    getTurnDoctorDelivery() {
      this.show = true;
      this.clearTurnDoctorDelivery();
      const apiRoute =
        "entregas/" +
        this.form.date +
        "/" +
        this.form.pavilion +
        "/" +
        this.form.schedule;
      this.axios
        .get(apiRoute)
        .then((response) => {
          this.items = response.data;
          this.show = false;
        })
        .catch((err) => {
          console.log(err);
          this.show = false;
        });
    },
    clearTurnDoctorDelivery() {
      this.items = [];
    },
  },
};
</script>

<style>
.rounded-input {
  border-radius: 1rem;
}
</style>
