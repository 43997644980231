<template>
  <div id="app">
    <turnDoctor></turnDoctor>
  </div>
</template>

<script>
import turnDoctor from "./pages/turnDoctor.vue";
export default {
  name: "App",
  components: {
    turnDoctor,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  font-weight: bold;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
